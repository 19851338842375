/**
* Generated automatically at built-time (2024-11-11T12:47:04.516Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-wine-cooler",templateKey: "sites/71-84f756e7-69a5-4831-b6fa-6a1d9cdd7891"};